import React from "react";
import useMediaQuery from '@mui/material/useMediaQuery';
import Navbar from "./navbar";

const Layout = ({ children }) => {
  const isSmallScreen = useMediaQuery('(max-width:1070px)'); // Custom media query for 1000px

  return (
    <div>
      {!isSmallScreen && (
        <>
          <Navbar>{children}</Navbar>
        </>
      )}
      {isSmallScreen && (
        <>
          <div style={{backgroundColor:'#101214'}}>
            <Navbar />
            {children}
          </div>
        </>
      )}
    </div>
  );
};

export default Layout;

import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Snackbar, Alert } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import img from "../../assets/mark1.png";
import editIcon from "../../assets/edit-svgrepo-com 1.svg";
import deleteicon from "../../assets/bin-with-lid 1.svg";
import filterIcon from "../../assets/filterIcon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "./index.css";

import Header from "./header";
import DeleteModal from "../../components/DeleteModal/index.jsx";
import Loading from "../../components/loading";

import { displayNumbers } from "../../lib/tools";

import { getTasks, deleteTask, getNewTaskGroup } from "../../apis/tasks.js";

const TaskList = [
  {
    img: "taskIcons/Play_Store.png",
    title: "PlayStore",
    description: "Download the Bluemoon App",
    completetion: 15,
    rewards: 100000,
    started_Date: "1/11/2023",
    ended_Date: "24/11/2023",
    id: "34234234234",
    type: 0,
  },
];

const TaskPage = () => {
  const goTo = useNavigate();

  const [selectedTaskButton, setSelectedTaskButton] = useState(0);
  const [selectedLive, setSelectedLive] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [taskList, setTaskList] = useState([{}]);

  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [taskGroup, setTaskGroup] = useState();
  const [tastName, setTaskName] = useState("New Task");

  const handleNewTaskButtonClick = (index) => {
    setSelectedTaskButton(index);
  };

  const handleEssentialTaskButtonClick = () => {
    setSelectedTaskButton(1);
  };

  const handleBonusTaskButtonClick = () => {
    setSelectedTaskButton(2);
  };

  const handleLiveButtonClick = () => {
    setSelectedLive(0);
  };

  const handleEndedButtonClick = () => {
    setSelectedLive(1);
  };

  const handleDeleteButtonClick = (itemId) => {
    setOpenDeleteModal(true);
    setCurrentItem(itemId);
  };

  const deleteItem = async (itemId) => {
    let res = await deleteTask(taskList[itemId].id);
    if (res.success === false) {
      setSnackbarMessage(res.message);
      setSnackbarStatus("warning");
      setIsOpenSnackbar(true);
    } else {
      setTaskList(taskList.filter((item, key) => key !== itemId));
      setSnackbarMessage("Deleted Successfully!");
      setSnackbarStatus("success");
      setIsOpenSnackbar(true);
    }
  };

  const handleEditButtonClick = (itemId) => {
    console.log(taskList[itemId]);
    localStorage.setItem("description", taskList[itemId].description);
    localStorage.setItem("task_id", taskList[itemId].id);
    localStorage.setItem("rewards", taskList[itemId].rewards);
    localStorage.setItem("title", taskList[itemId].title);
    localStorage.setItem("link", taskList[itemId].link);
    let tp = taskList[itemId].type === "new" ? 0 : "essential" ? 1 : 2;
    localStorage.setItem("type", tp);
    localStorage.setItem("img", taskList[itemId].img);
    goTo("/edit_task");
  };

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      let is_live = selectedLive === 0 ? true : false;
      let task_type =tastName
      let res = await getTasks(task_type, is_live);
      if (res.success) {
        console.log(res);
        let missions = res.missions;

        setTaskList(
          missions?.map((mission) => ({
            img:mission.photoUrl,
            title: mission.title,
            description: mission.description,
            completetion: mission.task_complete,
            rewards: mission.bonus,
            started_Date: mission.createdAt.split("T")[0],
            ended_Date:
              mission.date_ended === undefined ? "-" : mission.date_ended,
            link: mission.link,
            id: mission._id,
          }))
        );
        setSnackbarMessage("Loaded Successfully!");
        setSnackbarStatus("success");
        setIsOpenSnackbar(true);
      } else {
        setSnackbarMessage("Load faild!");
        setSnackbarStatus("warning");
        setIsOpenSnackbar(true);
      }
      setIsLoading(false);
    };
    run();
  }, [selectedLive, selectedTaskButton,tastName]);

  async function TaskGroups() {
    try {
      const res = await getNewTaskGroup();
      console.log(res);
      setTaskGroup(res?.mission);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    TaskGroups();
  }, []);

  return (
    // <Layout>
    <>
      {/* <Loading disp={isLoading}/> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setIsOpenSnackbar(false);
        }}
      >
        <Alert
          onClose={() => {
            setIsOpenSnackbar(false);
          }}
          severity={snackbarStatus}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        deleteCurrentItem={() => {
          deleteItem(currentItem);
        }}
      />
      <div
        style={{
           paddingTop: "80px",
           
           zIndex: "9999999",marginLeft:"20px" }}
        className="landing-contain-content"
      >
        <Header />
        <div className="landing-contain-section">
          <Card
            // title="Card title"
            bordered={false}
            style={{
              // width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "10px",
              marginTop: "30px",
              // padding:"10px 20px",
              gap:"24px",
              background: "rgba(0,0,0,1)",
             
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  borderRadius:"20px",
                  padding:"5px",
                  gap:"5px",
                  width:"fit-content",
                  border: "1px solid #509DFF33"
                }}
              >
                {taskGroup?.map((item, index) => {
                  return (
                    <div className="tasks-button">
                      <button
                        className={
                          selectedTaskButton === index ? "selected" : ""
                        }
                        onClick={() => {
                          handleNewTaskButtonClick(index);
                          setTaskName(item?.groupName);
                        }}
                      >
                        <span class="text-gradient">{item?.groupName}</span>
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="live-ended">
              <button
                className={selectedLive === 0 ? "selected" : ""}
                onClick={handleLiveButtonClick}
              >
                Live
              </button>
              <button
                className={selectedLive === 1 ? "selected" : ""}
                onClick={handleEndedButtonClick}
              >
                Ended
              </button>
            </div>
          </Card>
          <TableContainer
            component={Paper}
            style={{
              msOverflowStyle: "none",
              scrollbarWidth: "none",
              backgroundColor: "rgba(0,0,0,1)",
              borderRadius: "10px",
              boxShadow:"none"
            }}
          >
            <Table
              className="for-border"
              sx={{
                minWidth: 650,
                backgroundColor: "#101214",
                border: "none",
               
              }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontFamily: "Inter",
                      color: "#F4F6F7",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #16202B",
                      opacity:"0.5"
                    }}
                    align="center"
                  >
                    Tasks Image
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Inter",
                      color: "#F4F6F7",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #16202B",
                      opacity:"0.5"
                    }}
                  >
                    Title / Description
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Inter",
                      color: "#F4F6F7",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #16202B",
                      opacity:"0.5"
                    }}
                  >
                    Task Completion
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Inter",
                      color: "#F4F6F7",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #16202B",
                      opacity:"0.5"
                    }}
                  >
                    Rewards
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Inter",
                      color: "#F4F6F7",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #16202B",
                      opacity:"0.5"
                    }}
                  >
                    Date Started
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Inter",
                      color: "#F4F6F7",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #16202B",
                      opacity:"0.5"
                    }}
                  >
                    Date Ended
                  </TableCell>
                  <TableCell
                    style={{
                      fontFamily: "Inter",
                      color: "#F4F6F7",
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "14px",
                      borderBottom: "1px solid #16202B",
                      opacity:"0.5"
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {taskList.map((task, key) => (
                  <TableRow key={key}>
                    <TableCell
                      className="neechykrna"
                      component="th"
                      scope="row"
                      align="center"
                    >
                      <img
                        src={task.img}
                        alt="task"
                        style={{
                          width: "52px",
                          height: "52px",
                          borderRadius: "100%",
                        }}
                      />
                    </TableCell>
                    <TableCell className="neechykrna">
                      <h4
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "700",
                          fontSize: "16px",
                          color: "#F4F5F7",
                          lineHeight: "24px",
                          margin: "0px",
                        }}
                      >
                        {task.title}
                      </h4>
                      <p
                        style={{
                          fontFamily: "Inter",
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#F4F6F780",
                          lineHeight: "21px",
                          margin: "0px",
                        }}
                      >
                        {task.description}
                      </p>
                    </TableCell>
                    <TableCell
                      className="neechykrna"
                      style={{
                        fontFamily: "Inter",
                        color: "#F4F6F7",
                        fontWeight: "400",
                        fontSize: "14px",
                        
                        lineHeight: "21px",
                      }}
                    >
                      {task.completetion}
                    </TableCell>
                    <TableCell
                      className="neechykrna"
                      style={{
                        fontFamily: "Inter",
                        color: "#F4F6F7",
                        fontWeight: "400",
                        fontSize: "14px",
                        
                        lineHeight: "21px",
                      }}
                    >
                      {displayNumbers(task.rewards)}
                    </TableCell>
                    <TableCell
                      className="neechykrna"
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#F4F6F780",
                        lineHeight: "21px",
                      }}
                    >
                      {task.started_Date}
                    </TableCell>
                    <TableCell
                      className="neechykrna"
                      style={{
                        fontFamily: "Inter",
                        fontWeight: "500",
                        fontSize: "14px",
                        color: "#F4F6F780",
                        lineHeight: "21px",
                      }}
                    >
                      {selectedLive === 1 ? task.ended_Date : ""}
                    </TableCell>
                    <TableCell
                      className="neechykrna"
                      style={{ padding: "2px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "5px",
                          position: "relative",
                          right: "10px",
                        }}
                      >
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleEditButtonClick(key);
                          }}
                        >
                          <img
                            src={editIcon}
                            alt="edit"
                            style={{ width: "22px", height: "22px" }}
                          />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleDeleteButtonClick(key);
                          }}
                        >
                          <img
                            src={deleteicon}
                            alt="delete"
                            style={{ width: "22px", height: "22px" }}
                          />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
    // </Layout>
  );
};

export default TaskPage;

import React from "react"

export default {
    // main_url: "https://buzzmiadminbe.blockmoblabs.com/"
    
    main_url: "https://buzzmi-backend-wr4g.vercel.app/"

    // main_url: "https://adminbe.orbit-z.com/"
}

